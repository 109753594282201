import { theme } from '../../../styles'

export type ILink = {
  id?: string
  className?: string
  children: string | React.ReactNode
  url?: string
  underline?: boolean
  color?: string
  type?: string
  target?: string
  testId?: string
  onClick?: () => void
} & React.AnchorHTMLAttributes<HTMLAnchorElement>

const TYPE = {
  extern: 'nofollow',
  sponsored: 'sponsored, nofollow',
}

export function Link({
  id,
  className,
  children,
  url,
  underline,
  color,
  type,
  target,
  testId,
  onClick,
  ...rest
}: ILink): JSX.Element {
  if (!url) {
    return <>{children}</>
  }

  return (
    <>
      <a
        id={id}
        href={url}
        className={`Link${className ? ` ${className}` : ''}`}
        rel={`${TYPE[type] || ''} ${target === '_blank' ? 'noopener' : ''}`.trim()}
        target={target}
        onClick={onClick}
        data-testid={testId}
        style={{
          ...(underline
            ? {
                textDecoration: 'underline',
                textUnderlineOffset: '0.2em',
                textDecorationColor: theme.cssVars.focusBlue,
              }
            : {}),
        }}
        {...rest}
      >
        {children}
        {target === '_blank' ? <span className="sr-only"> (nouvelle fenêtre)</span> : null}
      </a>
      <style jsx>{`
        .Link {
          color: ${color || 'inherit'};
        }
      `}</style>
    </>
  )
}
