import { useEffect, useState, useContext } from 'react'
import { ITimeManager, TimeManagerContext } from '../../../contexts/TimeManager'
import { format, formatDate } from '../../../helpers/time'

export type ITime = {
  publishedAt: string
  updatedAt?: string
  enableSEO?: boolean
}

export function Time({ publishedAt, updatedAt, enableSEO }: ITime): JSX.Element {
  const publishedAtWithoutTimeZone = publishedAt?.split('+')[0] || ''
  const updatedAtWithoutTimeZone = updatedAt?.split('+')[0] || ''
  const { prefix, formatType }: ITimeManager = useContext(TimeManagerContext)
  const [hasScrolled, setHasScrolled] = useState(false)

  const dateFormatted = updatedAt
    ? format({
        publishedAt: new Date(publishedAtWithoutTimeZone),
        updatedAt: new Date(updatedAtWithoutTimeZone),
        formatType,
        prefix,
      })
    : formatDate({ date: new Date(publishedAtWithoutTimeZone), formatType, prefix })

  const dateSelected = enableSEO && !hasScrolled ? dateFormatted.dateSEO : dateFormatted.date

  const handleScroll = () => {
    setHasScrolled(true)
  }

  useEffect(() => {
    if (
      enableSEO &&
      dateFormatted.dateSEO !== dateFormatted.date &&
      dateSelected !== dateFormatted.date &&
      !hasScrolled
    ) {
      window.addEventListener('scroll', handleScroll, { once: true, passive: true })
      return () => {
        window.removeEventListener('scroll', handleScroll)
      }
    }
  }, [enableSEO, dateSelected])

  return <>{dateSelected}</>
}
